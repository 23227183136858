import { DutyWorkEventSelection } from 'views/Schedules/DutyDialog/DutyDialog'
import {
    ColorPalettes,
    ColumnOverride,
    DetailDataWithTimelineTicks,
    NumberOrNull,
    Permission,
    ScheduleDetailsViewMode,
    TableOptionsFilterSelections,
    TimeModeEnum,
    ViewSettings,
} from './interfaces'
import { DutyAutoWorkType } from './Metadata'
import { parseScenarioParameters, ScenarioParameters } from './Scenario'
import ScheduleEvent, { TagKeyValue } from './ScheduleEvent'
import { parseShiftSchedule, Shift } from './Shifts'
import { TagValues } from './TagValues'

export interface CreateUpdateShiftsSchedule extends CreateEventsSchedule {
    scheduleId?: number
    lastModified?: Date
    startDate: Date
    patternId: number
    shifts: Shift[]
}

export interface CreateEventsSchedule {
    scenarioId: number
    scenarioName: string
    scheduleName: string
    baseCode: string
}

interface ScheduleMetrics {
    minutes: number
    minutesCrewing: number
    minutesCritical: number
    effectivenessAvg: number
    effectivenessMax: number
    effectivenessMin: number
    effectivenessCrewingAvg: number
    effectivenessCrewingMax: number
    effectivenessCrewingMin: number
    effectivenessCriticalAvg: number
    effectivenessCriticalMax: number
    effectivenessCriticalMin: number
    kssCriticalMax: number
    samnPerelliCriticalMax: number
    reservoirAvg: number
    reservoirMax: number
    reservoirMin: number
    reservoirCrewingAvg: number
    reservoirCrewingMax: number
    reservoirCrewingMin: number
    reservoirCriticalAvg: number
    reservoirCriticalMax: number
    reservoirCriticalMin: number
    minutesBelowCriterion: number
    minutesBelowCriterionCrewing: number
    minutesBelowCriterionCritical: number
    percentBelowCriterion: number
    percentBelowCriterionCrewing: number
    percentBelowCriterionCritical: number
    numberofExcessivePBCEvents: number
    numberofExcessivePBCEventsCrewing: number
    numberofExcessivePBCEventsCritical: number
    totalFatigueHazardArea: number
    totalFatigueHazardAreaCrewing: number
    totalFatigueHazardAreaCritical: number
    percentFatigueHazardArea: number
    percentFatigueHazardAreaCrewing: number
    percentFatigueHazardAreaCritical: number
    workloadMax: number
    workloadMed: number
    numberOfEASANightType1Duties: number
    numberOfEASANightType2Duties: number
    numberOfEASANightType3Duties: number
}

export const createEmptySchedule = (name: string): Schedule => {
    return {
        id: 0,
        sourceScheduleId: null,
        scenarioOwnerId: 0,
        modifiedByUserId: 0,
        viewedByUserId: 0,
        scenarioOwnerName: '',
        modifiedByUserName: '',
        scheduleOwnerName: '',
        userCanEdit: false,
        errors: [],
        events: [],
        allScheduleTagNames: [],
        allScheduleEventTagNames: [],
        name,
        scenarioId: 0,
        scenarioName: '',
        scenarioParameters: {
            awakeZoneStart: '',
            awakeZoneEnd: '',
            bedtime: '',
            commuteType: 'VariableEvents',
            eventLabel: '',
            maxWorkDaySleep: 0,
            maxRestDaySleep: 0,
            minimumSleep: 0,
            commute: 0,
            criterionLevel: 0,
            percentBelowCriterionThreshold: 0,
            editSleep: false,
            autoNap: false,
            plannedWorkSleep: false,
            plannedWorkSleepAutoSleep: false,
            ignoreExplicitSleep: false,
            plannedWorkSleepRules: [],
        },
        viewSettings: {
            overlays: {
                rightAxis: null,
                standardDeviation: false,
            },
            tableFilters: {
                includeAutoSleep: false,
                includeCrewing: true,
                includeNonCrewing: true,
                includeMarker: true,
                includeExplicitSleep: true,
            },
            timeMode: TimeModeEnum.Local,
            viewMode: ScheduleDetailsViewMode.Both,
            columnOverrides: [],
        },
        colorPalettes: {
            effectiveness: [],
            percentage: [],
            reservoir: [],
            insights: [],
            excessive: [],
        },
        baseLocation: '',
        startStation: '',
        created: new Date(),
        viewed: new Date(),
        modifiedDate: new Date(),
        modified: new Date(),
        tagCollection: [],
        permission: {
            amOwnerOfSharedItem: false,
            isSharedThroughChildItem: false,
            itemId: 0,
        },
        warning: [],
        metrics: {
            minutes: 0,
            minutesCrewing: 0,
            minutesCritical: 0,
            effectivenessAvg: 0,
            effectivenessMax: 0,
            effectivenessMin: 0,
            effectivenessCrewingAvg: 0,
            effectivenessCrewingMax: 0,
            effectivenessCrewingMin: 0,
            effectivenessCriticalAvg: 0,
            effectivenessCriticalMax: 0,
            effectivenessCriticalMin: 0,
            kssCriticalMax: 0,
            samnPerelliCriticalMax: 0,
            reservoirAvg: 0,
            reservoirMax: 0,
            reservoirMin: 0,
            reservoirCrewingAvg: 0,
            reservoirCrewingMax: 0,
            reservoirCrewingMin: 0,
            reservoirCriticalAvg: 0,
            reservoirCriticalMax: 0,
            reservoirCriticalMin: 0,
            minutesBelowCriterion: 0,
            minutesBelowCriterionCrewing: 0,
            minutesBelowCriterionCritical: 0,
            percentBelowCriterion: 0,
            percentBelowCriterionCrewing: 0,
            percentBelowCriterionCritical: 0,
            numberofExcessivePBCEvents: 0,
            numberofExcessivePBCEventsCrewing: 0,
            numberofExcessivePBCEventsCritical: 0,
            totalFatigueHazardArea: 0,
            totalFatigueHazardAreaCrewing: 0,
            totalFatigueHazardAreaCritical: 0,
            percentFatigueHazardArea: 0,
            percentFatigueHazardAreaCrewing: 0,
            percentFatigueHazardAreaCritical: 0,
            workloadMax: 0,
            workloadMed: 0,
            numberOfEASANightType1Duties: 0,
            numberOfEASANightType2Duties: 0,
            numberOfEASANightType3Duties: 0,
        },
    }
}

/**
 * Get the duty event selection for the given scheduleEvent uuid which should correspond to a duty rollup event.
 * @param dutyEventUuid
 * @param schedule
 * @param dutyWorkTypes
 * @param isFixedBufferParameter
 * @returns
 */
export const getDutyEventSelection = (
    dutyEventUuid: string,
    schedule: Schedule,
    dutyWorkTypes: DutyAutoWorkType[],
    isFixedBufferParameter: boolean,
): DutyWorkEventSelection => {
    const dutyRollupEvent = schedule.events.find((x) => x.uuid === dutyEventUuid)
    const dutyEventSelection: DutyWorkEventSelection = {
        Preparation: { included: false },
        CommuteToWork: { included: false },
        Brief: { included: false },
        Debrief: { included: false },
        CommuteFromWork: { included: false },
        Unwind: { included: false },
    }

    if (!dutyRollupEvent || !dutyRollupEvent.isDutyRollup()) {
        return dutyEventSelection
    }

    const allDutyEvents = schedule.events.filter((evt) => evt.dutyUuid === dutyRollupEvent.dutyUuid)

    dutyWorkTypes.forEach((workType) => {
        allDutyEvents.forEach((dutyEvent) => {
            if (dutyEvent.workType === workType.workType) {
                dutyEventSelection[workType.workType].included = true
            } else if (isFixedBufferParameter && workType.workType !== 'Brief' && workType.workType !== 'Debrief') {
                // disable all but Brief & Debrief when FixedBuffer (unless already included in the schedule somehow)
                dutyEventSelection[workType.workType].disabled = true
            }
        })
    })

    return dutyEventSelection
}

export const parseSchedule = (data: any): Schedule => {
    let rightAxis = null
    let standardDeviation = false
    let timeMode = TimeModeEnum.Local
    let viewMode = ScheduleDetailsViewMode.Both
    let columnOverrides: ColumnOverride[] = []
    let includeCrewing = true
    let includeNonCrewing = true
    let includeMarker = true
    let includeExplicitSleep = true
    let includeAutoSleep = true

    const overrides = data.optionOverrides
    if (overrides) {
        if (overrides.editorRightAxis.toLowerCase() !== 'none') {
            rightAxis = overrides.editorRightAxis
        }
        standardDeviation = overrides.showStandardDeviation
        timeMode = TimeModeEnum[overrides.editorTimeReference as keyof typeof TimeModeEnum]
        viewMode = ScheduleDetailsViewMode[overrides.editorView as keyof typeof ScheduleDetailsViewMode]
        columnOverrides = Object.keys(overrides.editorTableColumns).reduce<ColumnOverride[]>((sum, cur) => {
            const col = overrides.editorTableColumns[cur]
            const colKeys = Object.keys(col)
            if (colKeys.find((x) => x.indexOf('eventTagPropertyValues') === 0)) {
                // is a column of one or more tags; this is a legacy format that is a bit akward
                colKeys.forEach((key) => {
                    const tagCol = col[key]
                    const tagName = key.replace('eventTagPropertyValues.', '').replace('.value', '')
                    sum.push({ field: `tagValues.${tagName}`, isChecked: tagCol.isChecked, isShown: tagCol.isShown })
                })
            } else {
                sum.push({ field: cur, isChecked: col.isChecked, isShown: col.isShown })
            }
            return sum
        }, [])

        includeCrewing = overrides.tableIncludeCrewing
        includeNonCrewing = overrides.tableIncludeNonCrewing
        includeMarker = overrides.tableIncludeMarker
        includeExplicitSleep = overrides.tableIncludeExplicitSleep
        includeAutoSleep = overrides.tableIncludeAutoSleep
    }

    const schedule = data as Schedule
    schedule.viewSettings = {
        overlays: {
            rightAxis,
            standardDeviation,
        },
        tableFilters: {
            includeCrewing,
            includeNonCrewing,
            includeMarker,
            includeExplicitSleep,
            includeAutoSleep,
        },
        timeMode,
        viewMode,
        columnOverrides,
    }

    const lastModified = new Date(Date.parse(data.modified))
    schedule.viewed = new Date(Date.parse(data.viewed))
    schedule.created = new Date(Date.parse(data.created))
    schedule.modified = lastModified
    schedule.modifiedDate = new Date(Date.parse(data.modifiedDate))
    schedule.sourceScheduleId = NumberOrNull(data.sourceScheduleId)
    schedule.scenarioParameters = parseScenarioParameters(data.scenarioParameters)
    if (data.shift) {
        schedule.shift = parseShiftSchedule(data.shift)
    }

    // flatten tags so the kendo grid can consume them
    schedule.tagValues = {}
    for (let i = 0; i < schedule.tagCollection.length; i++) {
        const tagEntry = schedule.tagCollection[i]
        schedule.tagValues[tagEntry.name] = tagEntry.value
    }

    return schedule
}

export const filterTableEvents = (events: ScheduleEvent[], filter: TableOptionsFilterSelections) => {
    return events
        .filter((x) => !x.crewing || filter.includeCrewing)
        .filter((x) => !(!x.crewing && x.isAnyWork()) || filter.includeNonCrewing)
        .filter((x) => !x.isAnyMarker() || filter.includeMarker)
        .filter((x) => !x.isAutoSleep() || filter.includeAutoSleep)
        .filter((x) => !x.isExplicitSleep() || filter.includeExplicitSleep)
}

export interface ScheduleWithEvents {
    id: number
    scenarioId: number
    name: string
    modified: Date
    scenarioName: string
    events: ScheduleEvent[]
    viewSettings: ViewSettings
}

interface Schedule extends ScheduleWithEvents {
    id: number
    name: string
    scenarioName: string
    scenarioOwnerName: string
    viewed: Date
    created: Date
    modified: Date // ?  why modified & modifiedDate??
    modifiedDate: Date
    baseLocation: string
    startStation: string
    scheduleOwnerName: string
    modifiedByUserName: string
    viewedByUserName?: string
    viewSettings: ViewSettings
    draftScheduleId?: number
    viewedByUserId: number
    scenarioId: number
    sourceScheduleId: number | null
    modifiedByUserId: number
    scenarioOwnerId: number

    isFailedSchedule?: boolean
    userCanEdit: boolean

    metrics: ScheduleMetrics
    permission: Permission
    errors: OverlapError[]
    warning: any[]
    colorPalettes: ColorPalettes
    scenarioParameters: ScenarioParameters
    tagCollection: TagKeyValue[]
    allScheduleTagNames: string[]
    allScheduleEventTagNames: string[]
    allNonCrewing?: boolean
    detailItemsJson?: string
    graphLightIntervals?: any
    optionOverrides?: any
    shift?: any

    // calculated on the client-side
    tagValues?: TagValues
    detailDataWithTimelineTicks?: DetailDataWithTimelineTicks
}

export type OverlapError = {
    overlapEvent1Uuid: string
    overlapEvent2Uuid: string
}

export interface ScheduleResponse {
    message: string | null
    schedule: Schedule
}

export default Schedule
